import { mapActions, mapGetters } from "vuex";

import router from "@/router";

export default {
    data() {
        return {
            courseTheme: null,
            coursesForTheme: [],
            disabledButton: false,
            authToken: localStorage.getItem("user_token"),
        };
    },
    name: "webinar",
    components: {},
    computed: {
        ...mapGetters({
            quizToken: "courses/quizToken",
        }),
    },
    created() {
        this.getCourseTheme(this.$route.params.id).then(courseTheme => {
            this.courseTheme = courseTheme;
        });
        this.getCoursesForTheme(this.$route.params.id).then(coursesForTheme => {
            this.coursesForTheme = coursesForTheme;
        });
    },
    methods: {
        ...mapActions({
            startQuiz: "courses/START_QUIZ",
            getCourseTheme: "courses/GET_COURSE_THEME",
            getCoursesForTheme: "courses/GET_COURSES_LIST",
        }),
        goToTesting(courseId) {
            this.disabledButton = true;
            this.startQuiz({ courseThemeId: this.$route.params.id, courseId: courseId }).then(() => {
                router.push({ name: "quiz", query: { id: courseId, quiz_token: this.quizToken } });
            });
        },
    },
};
